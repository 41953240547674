// 全ページ共通のloadingアイコン
.loadingWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .loader {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
}